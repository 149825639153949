import isPast from 'date-fns/isPast';
import addDays from 'date-fns/addDays';

const isActiveUntil = (date) => !isPast(addDays(new Date(date), 1));

const specialEvents = [
  {
    href: 'https://contenido.roll-bits.com/kenia-os-29nov2024p_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a-f01/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/kenia-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/kenia-banner.jpg',
    alt: 'Kenia 29 de Noviembre',
    descriptionText: `Kenia: 29 de Noviembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-11-29'),
  },
  {
    href: 'https://contenido.roll-bits.com/kenia-os-30nov2024p_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-06a-f01-ifw/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/kenia-circle-2.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/kenia-banner-2.jpg',
    alt: 'Kenia 30 de Noviembre',
    descriptionText: `Kenia: 30 de Noviembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-11-30'),
  },
  {
    href: 'https://contenido.roll-bits.com/morat_02_dic_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec/?nocache=17881',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-circle.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-banner.jpg`,
    alt: 'Morat 02 Diciembre',
    descriptionText: `Morat: 02 Diciembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-12-02'),
  },
  {
    href: 'https://contenido.roll-bits.com/real-de-14-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-85e-v5i-4uk-6nw/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/real-catorce-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/real-catorce-banner.jpg',
    alt: 'Real de Catorce 07 de Diciembre',
    descriptionText: 'Real de Catorce: 07 de Diciembre',
    isSlider: true,
    isActive: isActiveUntil('2024-12-07'),
  },
  {
    href: 'https://contenido.roll-bits.com/morat_13_dic_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2/?nocache=21741',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-circle-3.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-banner-3.jpg`,
    alt: 'Morat 13 Diciembre',
    descriptionText: `Morat: 13 Diciembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-12-13'),
  },
  {
    href: 'https://contenido.roll-bits.com/morat_14_dic_mxl-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k/?nocache=85120',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-circle-4.svg',
    banner: `https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/morat-banner-4.jpg`,
    alt: 'Morat 14 Diciembre',
    descriptionText: `Morat: 14 Diciembre`,
    isSlider: true,
    isActive: isActiveUntil('2024-12-14'),
  },
  {
    href: 'https://contenido.roll-bits.com/kygo-25-enero-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-meb-u8o-ru5-wsg-3kw-era-rk6/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/kygo-circle-250125.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/kygo-banner-250125.jpg',
    alt: 'Kygo 25 de Enero',
    descriptionText: `Kygo: 25 de Enero`,
    isSlider: true,
    isActive: isActiveUntil('2025-01-25'),
  },
  {
    href: 'https://contenido.roll-bits.com/aespa-04-febrero-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-meb-u8o-ru5-wsg-3kw-era/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/aespa-circle-040225.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/aespa-banner-040225.jpg',
    alt: 'Aespa 04 de Febrero',
    descriptionText: `Aespa: 04 de Febrero`,
    isSlider: true,
    isActive: isActiveUntil('2025-02-04'),
  },
  {
    href: 'https://contenido.roll-bits.com/the-warning-6-feb-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-meb-u8o-ru5-wsg-3kw-era-d9p/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/warning-circle-060225.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/warning-banner-060225.jpg',
    alt: 'The warning 06 de Febrero',
    descriptionText: `The warning: 06 de Febrero`,
    isSlider: true,
    isActive: isActiveUntil('2025-02-06'),
  },
  {
    href: 'https://contenido.roll-bits.com/twenty-one-pilots-20-feb_-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-kat/?nocache=79274',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/twenty-one-pilots-circle.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/twenty-one-pilots-banner.jpg',
    alt: 'Twenty One Pilots 20 de Febrero',
    descriptionText: `Twenty One Pilots: 20 de Febrero`,
    isSlider: true,
    isActive: isActiveUntil('2025-02-20'),
  },
  {
    href: 'https://contenido.roll-bits.com/twenty-one-pilots-22-feb_-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-vzb-9ug-59x-wec-zgm-7w2-23k-kat-1qi/?nocache=17310',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/twenty-one-pilots-circle-2.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/twenty-one-pilots-banner-2.jpg',
    alt: 'Twenty One Pilots 22 de Febrero',
    descriptionText: `Twenty One Pilots: 22 de Febrero`,
    isSlider: true,
    isActive: isActiveUntil('2025-02-22'),
  },
  {
    href: 'https://contenido.roll-bits.com/shakira-16-marzo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-d5g/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-circle-1603.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-banner-1603.jpg',
    alt: 'Shakira 16 de Marzo',
    descriptionText: `Shakira: 16 de Marzo`,
    isSlider: true,
    isActive: isActiveUntil('2025-03-16'),
  },
  {
    href: 'https://contenido.roll-bits.com/shakira-17-marzo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-d5g-2xq/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-circle-170325.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-banner-170325.jpg',
    alt: 'Shakira 17 de Marzo',
    descriptionText: `Shakira: 17 de Marzo`,
    isSlider: true,
    isActive: isActiveUntil('2025-03-17'),
  },
  {
    href: 'https://contenido.roll-bits.com/shakira-19-marzo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-circle-1903.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-banner-1903.jpg',
    alt: 'Shakira 19 de Marzo',
    descriptionText: `Shakira: 19 de Marzo`,
    isSlider: true,
    isActive: isActiveUntil('2025-03-19'),
  },
  {
    href: 'https://contenido.roll-bits.com/shakira-21-marzo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-circle-2103.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-banner-2103.jpg',
    alt: 'Shakira 21 de Marzo',
    descriptionText: `Shakira: 21 de Marzo`,
    isSlider: true,
    isActive: isActiveUntil('2025-03-21'),
  },
  {
    href: 'https://contenido.roll-bits.com/shakira-23-marzo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n-z8s/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-circle-230325.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-banner-230325.jpg',
    alt: 'Shakira 23 de Marzo',
    descriptionText: `Shakira: 23 de Marzo`,
    isSlider: true,
    isActive: isActiveUntil('2025-03-23'),
  },
  {
    href: 'https://contenido.roll-bits.com/shakira-25-marzo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n-z8s-g58/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-circle-250325.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-banner-250325.jpg',
    alt: 'Shakira 25 de Marzo',
    descriptionText: `Shakira: 25 de Marzo`,
    isSlider: true,
    isActive: isActiveUntil('2025-03-25'),
  },
  {
    href: 'https://contenido.roll-bits.com/shakira-27-marzo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n-z8s-g58-yqc-5w8/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-circle-270325.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-banner-270325.jpg',
    alt: 'Shakira 27 de Marzo',
    descriptionText: `Shakira: 27 de Marzo`,
    isSlider: true,
    isActive: isActiveUntil('2025-03-27'),
  },
  {
    href: 'https://contenido.roll-bits.com/shakira-28-marzo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n-z8s-g58-yqc/',
    img: 'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-circle-280325.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.amazonaws.com/funnel/events/shakira-banner-280325.jpg',
    alt: 'Shakira 28 de Marzo',
    descriptionText: `Shakira: 28 de Marzo`,
    isSlider: true,
    isActive: isActiveUntil('2025-03-28'),
  },
  {
    href: 'https://contenido.roll-bits.com/ghost-24-sep-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-meb-u8o-ru5-wsg-3kw-era-rk6-iag/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/ghost-circle-240925.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/ghost-banner-240925.jpg',
    alt: 'Ghost 24 de Septiembre',
    descriptionText: `Ghost: 24 de Septiembre`,
    isSlider: true,
    isActive: isActiveUntil('2025-09-24'),
  },
];

export default specialEvents;
